<template>
	<div class="brainPart w-full  ">
		<div class='bg-left-right  flex flex-wrap flex-col '>
			<TitleDes title="业务场景核心应用" :top="getVw(70)" :bottom="getVw(45)"  />
			<div class='w-[710px]  mr-auto ml-auto   mt-[15px]  mb-[55px] flex flex-wrap'>
				<div v-for="brain in brainData" :key="brain.id" class='
	 w-[300px]  brainCards  bg-white rounded-[10px]  px-[15px] m-[20px]  pt-6 pb-6 flex flex-col items-center'>
					<!-- <div class=" flex flex-col items-center"> -->
					<div class="w-[106px] h-[106px]   mb-[38px] brainIcons 
					" :style="{ backgroundImage: `url(${brain.icon})` }">
					</div>
					<div class="brainCardsTitle text-[30px] font-semibold leading-[30px] mb-[25px]
				
					">
						{{ brain.title }}
					</div>


					<div class="brainCardsDes  flex flex-col items-left text-12PX  w-full">
						<div v-for="(brainDes) in brain.des" :key="brainDes">
							{{ brainDes }}
						</div>

					</div>

				</div>
			</div>


		</div>
	</div>
</template>
<script setup>
import {getVw} from '@/utils/getVw'

import TitleDes from '@/components/title-des/index.vue';
import core_1 from '@/assets/imgs/homes/core_1.png';
import core_2 from '@/assets/imgs/homes/core_2.png';
import core_3 from '@/assets/imgs/homes/core_3.png';
import core_4 from '@/assets/imgs/homes/core_4.png';
import core_5 from '@/assets/imgs/homes/core_5.png';
import core_6 from '@/assets/imgs/homes/core_6.png';
const brainData = [
	{
		title: '产业监测',
		des: ['• 产业核心指标运行监测', '• 产业园区经济运行监测',  '……'],
		icon: core_1,
		id: 'core_1'
	}, {
		title: '数字产研',
		des: ['• 产业地区发展对标',  '• 产业报告与报表生成', '……'],
		icon: core_2,
		id: 'core_2'
	}, {
		title: '产业地图',
		des: ['• 产业主体区位分布布局','• 产业投资投向区域分布', '……'],
		icon: core_3,
		id: 'core_3'
	}, {
		title: '数智招商',
		des: ['• 企业推荐（招商情报）', '• 委托联系（企业触达）', '……'],
		icon: core_4,
		id: 'core_4'
	}, {
		title: '一企一档',
		des: ['• 重点关注企业档案', '• 龙头企业风险监测',  '……'],
		icon: core_5,
		id: 'core_5'
	}, {
		title: '产业服务',
		des: [ '• 产业通知及信息发布', '• 产业载体与政策公示', '……'],
		icon: core_6,
		id: 'core_6'
	}
]
</script>
<style lang="less" scoped>
.brainPart {
	.bg-left-right {
		width: 100%;
		height: 100%;
		background:white;
			// left 0px bottom 52px / contain no-repeat url("@/assets/imgs/homes/core_bg.png"),
			// white right top 85px/ contain no-repeat url("@/assets/imgs/homes/industry_bg.png");
		// background-size: 192px 215px, 386px 372px;
	}

	.brainCards {

		box-shadow: 0px 0px 20px 0px #EBEBEB;
// &:nth-child(n){
// 	float: left;
// }
// &:nth-child(2n){
// 	float: right;
// }
 
		.brainCardsDes {
			color: #999999;
			// margin-top: 12px;
			// padding: 8px;
		} 
		.brainIcons {
			// margin-right: 10px;
			background: center / contain no-repeat;
		}
	}

}
</style>
