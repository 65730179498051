<template>
	<!-- bg-white -->
	<div class=" w-full relative  bg-slate-600">
		<!-- 上 -->
		<div class=" w-full  bg-white h-[140px]"></div>
		<div class=" h-[385px] w-[690px] absolute   left-[5%] top-[0] ">
			<!-- 中-->
			<div class="footerChat h-full w-full z-10 mx-auto">
				<div class="  mx-[auto]  px-[85px] pt-[60px] pb-[41px] flex flex-wrap justify-between text-center  text-white">
					<!-- <div class="h-[170px] flex flex-wrap content-center "> -->
					<div class="w-full text-[36px] font-bold leading-[30px] mb-[40px]">
						艾瑞数云·产业金脑
					</div>
					<div class="text-[28px]  mb-[46px]">
						我们不止是数字化平台产品供应商，我们更是区域产业发展服务商！
					</div>
					<div class="chatButton w-[240px] h-[70px] leading-[70px] mx-[auto] rounded-[120px] bg-inherit text-center   bg-white 
             text-[#0A69F6]  flex   justify-center items-center"
						 @click="showDialog"
						 >
					<div class="bg-[url(@/assets/imgs/homes/chat_button.png)] w-[39px] h-[31px] bg-no-repeat bg-center bg-contain mr-[20px]"></div>	立即咨询
					</div>
					<!-- </div> -->

				</div>
			</div>
		</div>
		<!-- 下 -->
		<!--  mt-[-140px] -->
		<div class="h-[1150px] w-full bg-[#353B40]  pt-[260px]">
			<div class=" h-[290px] w-[690px]  mx-auto   top-[390px]  pt-[60px] pb-[40px] border-b-2 border-white/10">
				<!-- <div class="h-full    "> -->
					<!-- <div class="h-full first-letter: flex flex-wrap justify-between  "> -->
						<div class=" flex flex-nowrap   mb-[60px]">
							<div class="h-[165px]  mr-[52px] ">
								<div class="chatImg w-[128px] h-[170px] text-center  text-12PX text-white flex  ">
									微信公众号
								</div>
								<!-- <div class="w-[173px] h-[56px]  bg-contain  bg-center bg-no-repeat  logo"> </div> -->
							</div>
							<div class=" text-white/80  text-12PX  flex flex-col justify-between ">
								<div class=" w-full">
									电话：19157888002
								</div>
								<div class=" w-full">
									邮箱：admin@idicc.cn
								</div>
								<div class=" w-full">
									地址：浙江省杭州市余杭区仓前街道文一西路1324号
								</div>
							</div>
						</div>
					
					</div>
					<div class="w-[690px]   mx-auto  flex flex-wrap justify-between pt-[53px] mb-[30px]">
							<div v-for="(texts) in textData" :key="texts.id" class="w-[100%] ">
								<div class="text-[28px] text-white mb-[20px]  ">
									{{ texts.title }}
								</div>
								<div class="grid  grid-flow-row  grid-cols-3 justify-between "  >
								<div v-for="(child,index) in texts?.children" :key="child.router" class=" text-12PX text-white/30  mb-[35px]  
								"
								:class=" {'text-right':index===2,'text-center':index===1,'text-left':index===0}"
								@click="changeRoute(child)">
									{{ child.name }}
								</div>
							</div>
							</div>
							<div class='w-full flex justify-center  items-center pt-3 text-[20px] text-white/30 flex-wrap  '>
					<img class="h-[20px] px-1" src="@/assets/imgs/gongshang.png">
					<img class="h-[20px]" src="https://gw.alicdn.com/tfs/TB1jwakrbH1gK0jSZFwXXc7aXXa-20-20.png">
					<a class="px-2" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备2023019449号</a>
					<span>艾瑞数云科技(杭州)有限公司</span>
				</div>
						</div>
				<!-- </div> -->
			<!-- </div> -->
			<!-- <div v-if="show"  class="bg-black/70 fixed top-0 left-0 z-40 w-full h-full overscroll-contain">
		
					 <ConsultUsForm  @close='closeDialog' state="consult" />
		</div> -->
		</div>
	</div>
</template>
<script setup>
import { defineEmits, defineProps ,ref} from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter();
const textData = [
	{
		id: '产品方案',
		title: '产品方案',
		children: [
			{
				router: '/product/goldenBrain',
				name: '产业金脑',
			}, {
				router: '/product/industryResearch',
				name: '数字产研',
			}, {
				router: '/product/attractInvestment',
				name: '数智招商',
			},
		],
		// select: ['产业金脑', '数字产研', '数智招商']
	}, {
		id: '数据报告',
		title: '数据报告',
		children: [
			{
				router: '/report/1',
				name: '产业洞察',
			}, {
				router: '/report/2',
				name: '专题报告',
			}, {
				router: '/report/3',
				name: '智能研报',
			},
		],
		// select: ['产业洞察', '专题报告', '智能研报']
	}, {
		id: '关于我们',
		title: '关于我们',
		children: [
			{
				router: '/aboutUs/01',
				name: '公司简介',
			}, {
				router: '/aboutUs/02',
				name: '联系我们',
			},
			 
		],
		// select: ['公司简介', '联系我们']
	},

]
const show = ref(false);
// const props = defineProps('jump')
const emit = defineEmits(['jump',])
const changeRoute = (child) => {
	emit('jump', child.router)
}
const showDialog = () => {
  router.replace({
    path: '/consultUsForm/consult'
  })
  // isShowValue.value = true
}
// const closeDialog = () => {
// 	show.value= false
// }
</script>
<style lang="less">
.footerChat {
	background: center / contain no-repeat url("@/assets/imgs/homes/chat_bg.png"),
}

.chatImg {
	flex-flow: column-reverse;
	background: top / contain no-repeat url("@/assets/imgs/homes/code.png");
	background-size:128px 128px;
}

.logo {
	background-image: url(@/assets/imgs/homes/logo_white.png);
}
</style>