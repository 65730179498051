<template>
	<ConsultUsFormDetail  :state='state'  @close='closeDialog'  />
</template>
<script setup>
import { onMounted, ref, watch ,onBeforeMount} from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter();
const state = ref('');
const reportName = ref('');
const reportId = ref('');
 import ConsultUsFormDetail from '@/components/consultUsForm/index.vue'
 watch(()=>router.currentRoute.value.params.type,(newValue)=>{
	console.log(newValue)
	state.value = newValue
 })
 onBeforeMount(() => {
	state.value = router.currentRoute.value.params.type;
}
);
const closeDialog = () => {
	console.log('close')
	if(state.value ==='report'){
		router.push({
			path:'/report/1'
		})
	}else{
		router.push({
			path:'/homePage'
		})
	}
}
</script>
