<template>
	<div id="aboutUs" ref="aboutUs">
	<Top :backBg="img" title="艾瑞数云" des="助力产业高质量发展" />
	<!-- 公司简介 -->
	<div
		class="brainPart bg-[url(@/assets/imgs/aboutUs/aboutUs_bg1.png)]  bg-bottom  bg-no-repeat bg-contain w-full  bg-[#F2F7FC]">
		<div class=' flex flex-col  content-start '>
			<div class="h-[80px] mt-[70px] w-full">
				<TitleDes title="公司简介" />
			</div>
			<div class="w-[750px] mx-[auto] px-[58px] pb-[100px]">
				<div class="mt-[53px]  text-left text-[26px] text-[#666] leading-8">
				艾瑞数云科技（杭州）有限公司（简称“艾瑞数云”）致力于数据驱动产业发展的探索与实践，组建了一支IT、数据技术、产业经济学和行业领域专家跨界复合型团队，并发布“产业金脑”及相关产品服务。
				</div>
				<div class=" text-left text-[26px] text-[#666] leading-8">
					产业金脑系艾瑞数云自主知识产权研发的产业发展赋能平台，围绕产业研究、产业决策、产业招商、产业运营、产业投资等五类业务场景，建立企业、人才、技术为产业要素的数字底座，运用人工智能、AI大数据模型等技术，以数字分析产业、数智支持运营、数资赋能发展，全面构建产业链全景图，实时追踪产业发展动态，深度洞察产业发展趋势，发布产业发展白皮书，提供行业技术专利导航，助力区域产业高质量发展。
				</div>
			</div>
		</div>
	</div>
 
	<div class=' flex flex-wrap flex-col w-full h-[930px]  pt-[70px]'>
		<TitleDes title="联系我们"  />
		<div class="w-[100%]  flex flex-wrap mt-[35px]">
			<div
				class="w-[695px] h-[400px] mx-[auto]  bg-no-repeat bg-contain bg-center  bg-[url(@/assets/imgs/aboutUs/aboutUs_map.png)]">

			</div>
			<div
				class="chatCard w-full h-[335px]  p-[40px] ">
				<div class=" text-[#666] text-[28px]  flex flex-wrap justify-between ">
					<div class=" w-full flex flex-nowrap">
						<div
							class="bg-[url(@/assets/imgs/aboutUs/aboutUs_icon1.png)]  bg-center  bg-no-repeat bg-contain w-[51px] h-[51px] mr-4">
						</div>
						<div class="text-[28px] text-[#666]">电话：19157888002</div>
					</div>
					<div class=" w-full flex flex-nowrap mt-[23px] mb-[23px]">
						<div
							class="bg-[url(@/assets/imgs/aboutUs/aboutUs_icon2.png)]  bg-center  bg-no-repeat bg-contain w-[51px] h-[51px] mr-4">
						</div>
						<div class="text-[28px] text-[#666]"> 邮箱：admin@idicc.cn</div>
					</div>
					<div class=" w-full flex flex-nowrap">
						<div
							class="bg-[url(@/assets/imgs/aboutUs/aboutUs_icon3.png)] bg-center bg-no-repeat bg-contain w-[51px] h-[51px]  mr-4">
						</div>
						<div class="  text-[28px] text-[#666]"> 地址：浙江省杭州市余杭区仓前街道文一西路1324号</div>
					</div>
				</div>

			</div>
		
		</div>
	</div>
</div>
</template>
<script setup>
import Top from '@/components/top-img/index.vue';
import TitleDes from '@/components/title-des/index.vue';
import img from '@/assets/imgs/aboutUs/aboutUs_top.png';
import { watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter();
const aboutUs = ref(null)
watch(
	() => router.currentRoute.value.params.id,
	(newValue, oldValue) => {
		let value = newValue
		if (value === '02') {
		setTimeout(function() {
			window.scrollTo({
      top: 600,
      behavior: "smooth"
    })
  // window.scrollTo(0,600);
}, 1);
		}
	})
onMounted(() => {
	let value = router.currentRoute.value.params.id;
	if (value === '02') {
		setTimeout(function() {
  // window.scrollTo(0,600);
	window.scrollTo({
      top: 600,
      behavior: "smooth"
    })
}, 1);
	}
})
</script>

<style scoped>
 
</style>