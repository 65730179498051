<template>
	<div class="view-container">
		<Top :backBg="img" title="数字产研" des="以数字分析产业、数智支持运营、数资赋能发展" />
		<!-- 主要赋能业务场景 -->
		<div class=" w-full h-[846px]  blue_bg">
			<div class='bg-left-right  flex flex-col  content-start  '>
				<div class="h-[80px] mt-[70px] w-full">
					<TitleDes title="主要赋能业务场景" />
				</div>
				<div class="  mt-[62px] w-full">
					<productIntroductionSpecial2 :listData="businessScenario" />
				</div>
			</div>
		</div>
		<!-- 数字产研功能亮点 -->
		<div class=" w-full   ">
			<div class='bg-left   flex flex-col  content-start  '>
				<div class="h-[80px] mt-[70px] w-full">
					<TitleDes title="数字产研功能亮点" />
				</div>
				<div class=" justify-center grid grid-cols-2 gap-[30px] items-center mt-[50px] mb-[80px] w-[690px] mr-auto ml-auto ">
					<div v-for="light in  lightData " class='lightData w-[330px] h-[120px]  bg-white   
					' :key="light.id">
						<div class="flex flex-nowrap  h-full pl-[20px] ">
							<div class="w-[37px] h-[37px] lightIcons iconfont " :class="light.icon">
							</div>
							<!-- <div class="flex flex-wrap flex-col justify-start  lightText "> -->
								<div class="font-medium text-[28px] text-[#333333]">
									{{ light.title }}
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<IntroductionAndImg showBg="true" :showInfo="showInfo.show_1" flexCol="true"/>
		<IntroductionAndImg showBg="true" isRevert='true' :showInfo="showInfo.show_2" flexCol="true"/>
	</div>
</template>
<script setup>
import { onMounted, ref, defineComponent } from 'vue'
import TitleDes from '@/components/title-des/index.vue';
import productIntroductionSpecial2 from '@/components/productIntroductionSpecial2/index.vue'
import IntroductionAndImg from '@/components/introductionAndImg/index.vue'

import Top from '@/components/top-img/index.vue'
import img from '@/assets/imgs/product/chanyan_数字产研.png'
import icon1 from '@/assets/imgs/product/chanyan_产业研究.png'
import icon2 from '@/assets/imgs/product/chanyan_决策.png'
import icon3 from '@/assets/imgs/product/chanyan_招商.png'
import icon4 from '@/assets/imgs/product/chanyan_运营.png'
import icon5 from '@/assets/imgs/product/chanyan_投资.png'
import bgImg1 from '@/assets/imgs/product/chanyan_in1.png'
import bgImg2 from '@/assets/imgs/product/chanyan_in2.png'
import bgImg3 from '@/assets/imgs/product/chanyan_bg1.png'
import bgImg4 from '@/assets/imgs/product/chanyan_bg2.png'
import lightIcon1 from '@/assets/imgs/product/chanyan_lightIcon2.png'

const percentage = ref(10)
const lightData = [
	{
		title: '产业链全景',
		icon: 'icon-quanjingfenxi',
		des: '产业企业与产业链细分环节精准匹配，一图概览产业全貌'
	},
	{
		title: '产业链评价',
		icon: 'icon-daipingjia',
		des: '基于产业多维数据，客观评价地区产业综合发展实力'
	},
	{
		title: '产业发展对标',
		icon: 'icon-bifuduibi',
		des: '多区域、多维度对比分析，产业发展差异尽收眼底'
	},
	{
		title: '产业排行榜',
		icon: 'icon-paihang',
		des: '实时更新产业榜单，快速了解区域在全国、全省、全市的竞争位序'
	},
	{
		title: '强链补链分析',
		icon: 'icon-fenxiduibi',
		des: '地区产业链研判分析，识别优劣势细分环节，提供强链补链延链建议'
	},
	{
		title: '产业报告中心',
		icon: 'icon-baobiao',
		des: '一键生成产业智能报告，定期输出产业发展研报'
	}
]
const showInfo =
{
	show_1: {
		index: null,
		title: '一网观天下',
		des: '以驾驶舱视角观趋势、研形势，系统化洞察区域产业发展态势。',
		img: bgImg1,
		backBg: bgImg3,
		size: '39rem 6.18rem',
		position: '21.5rem 9.6rem',
		blueBg: true
		// place: 'left'
	}, show_2: {
		index: null,
		title: '一脉知江湖',
		des: '精准定位，准确研判，将全国企业精准定位到产业链细分环节，打造产业全景一张图。',
		img: bgImg2,
		backBg: bgImg4,
		size: '41.4rem 10.3rem',
		position: 'right 11.2rem top 9rem',
		// place:'right'

	},
}
const businessScenario = [
	{
		id: '01',
		title: '产业研究',
		des: ['为政府/机构/企业提供', '产业洞见'],
		des: '为政府/机构/企业提供产业洞见',
		icon: icon1,
	}, {
		id: '02',
		title: '产业决策',
		des: '为政府战略决策提供新工具',
		icon: icon2,
	}, {
		id: '03',
		title: '产业招商',
		des: '为地区产业集聚发展提供新指引',
		icon: icon3,
	}, {
		id: '04',
		title: '产业运营',
		des: '为区域产业精细化管理提供新范式',
		icon: icon4,
	}, {
		id: '01',
		title: '产业投资',
		des: '为机构产业投资分析提供新方向',
		icon: icon5,
	}
]
</script>
<style lang="less" scoped>
@import 'swiper/swiper-bundle.css';
@import 'swiper/css/pagination';

.blue_bg {
	background: #F2F7FC;
}

.title {
	font-family: "YouSheBiaoTiHei" !important;
}

.bg-left {
	background:
	white	left -23px top   / contain no-repeat url("@/assets/imgs/homes/industry_bg.png");
	background-size: 360px 372px;
}

.bg-left,
.bg-left-right {
	width: 100%;
	height: 100%;
}

.bg-left-right {
	background:white;
		// left 44px top 139px / contain no-repeat url("@/assets/imgs/homes/brain_bg1.png"),
		/* center / contain no-repeat url("@/assets/imgs/homes/brain_bg3.png"), */
		// #F2F7FC right 0 bottom / contain no-repeat url("@/assets/imgs/homes/brain_bg2.png");
	// background-size: 215px 192px, 279px 372px;
}

.lightIcons {
	margin-right: 30px;
	// background: center / contain no-repeat;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 40px;
	color: #007BFF
}

/* 
.lightPart {
	overflow: hidden;
} */

.lightData {
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0px 0px 51px 0px #EBEBEB;
	padding: 38px 20px 49px 28px;
 
 
	 

}

.bg_img {
	width: 42px;
	height: 42px;
	right: -4PX;
	bottom: -4PX;
	position: absolute;
	background: #A7D1FF;
	border-left-width: 2PX;
	border-top-width: 2PX;

	border-color: #007BFF;
	/* border: 2px solid #007BFF; */
	border-radius: 10px;
	border-top-left-radius: 50px;
	/* border-bottom-left-radius: 50px; */
}
</style>
