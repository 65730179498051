<template>
  <div class="view-container">
    <swiper class="w-full h-full" :loop="true" :centeredSlides="true"    :pagination="{ clickable: true }"
      :autoplay="{ delay: 20000, disableOnInteraction: false }" :navigation="false" :modules="modules">
      <swiper-slide v-for="(slides) in slideData" :key="slides?.id">
        <div class="w-full h-full bg-cover  bg-center bg-no-repeat " :style="{ backgroundImage: `url(${slides.imgs})` }">
          <!-- 文案 -->
          <div class="  h-[615px] text-white ">
            <div class="mt-[80px] h-[600px] flex flex-col  w-full flex-wrap justify-center text-center">
              <div class="text-[55px]  slidesTitle leading-[40px]"
               >{{
                  slides.title
                }} </div>
              <div class="text-[30px] text-center px-[70px]" >
                {{ slides.description }}
              </div>

            </div>
          </div>
          <!-- 文案 -->
        </div>
      </swiper-slide>
      <div class="vieBg z-10 "></div>
    </swiper>

  </div>
</template>
<script lang="ts" setup>
import slide1 from '@/assets/imgs/homes/slideshow_1.png'

import { ref } from 'vue'
// import { useRouter } from 'vue-router'
// import { Swiper, SwiperSlide } from 'swiper/vue' // swiper 所需组件
import { Autoplay, Navigation, Pagination, A11y } from 'swiper'// 分页器
import 'swiper/swiper-bundle.css';
const modules = [Autoplay, Pagination, Navigation, A11y]
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import slide2 from '@/assets/imgs/homes/slideshow_2.png'
import slide3 from '@/assets/imgs/homes/slideshow_3.png'
// const router = useRouter();

// const slide = ref([slide1, slide2, slide3],)
const slideData = ref([
  {
    id: '01',
    title: '艾瑞数云',
    description: '我们不只是数字化平台产品供应商，我们更是区域产业发展服务商。',
    showButton: false,
    buttonText: '关于我们',
    imgs: slide1
  }, {
    id: '02',
    title: '聚焦区域产业发展',
    description: '以数字分析产业、数智支持运营、数资赋能发展，服务各地方政府产业集群建设，助力产业高质量发展。',
    buttonText: '',
    showButton: false,
    imgs: slide2
  }, {
    id: '03',
    title: '大数据＋数据挖掘技术',
    description: '联通产业相关的企业、机构、人才、技术、产品、资金等数据资源，构成产业大数据数字底座，有效释放数据要素价值。',
    buttonText: '',
    showButton: false,
    imgs: slide3
  }

])

 

</script>
<style lang="less"  scoped>
@import 'swiper/swiper-bundle.css';
@import 'swiper/css/pagination';

.buttonText {
  padding: 0 8px;
  z-index: 222;
  margin: 80px auto 0;

 
}

.slidesTitle {
  padding-bottom: 56px;
}

.view-container {
  position: relative;
  width: 100%;
  height: 615px;
  margin-top: -80px;

  ::v-deep .swiper-pagination {
    z-index: 20;

    .swiper-pagination-bullet {
      width: 4px;
      margin: 0px 7px;
    }

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
      background: white;
      height: 4px;
      border-radius: 4px;
    }

    .swiper-pagination-bullet-active {
      width: 30px;
    }
  }
}

.vieBg {
  bottom: -1px;
  position: absolute;
  width: 100%;
  height: 45px;
  background: bottom / contain no-repeat url("@/assets/imgs/homes/slides_bottom.png");
}

 
</style>
