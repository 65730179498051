<template>
	<div class="onLineAndOffLinePart w-full ">
		<div class='bg-left-right  flex flex-wrap flex-col  '>
			<TitleDes title="线上+线下融合服务模式" textColor="white" lineColor='white' :top="getVw(70)" :bottom="getVw(45)" />
			<div class='w-full   flex flex-wrap justify-center   mb-[50px]'>
				<div v-for="brain in brainData" :key="brain.id" class='w-[690px]   mb-[46px] brainCards  relative  mx-[auto]'>
					<!-- 背景图 -->
					<!-- <div class="w-[380px] h-[380px] brainIcons" :style="{ backgroundImage: `url(${brain.icon})` }"></div> -->
					<!-- 白色卡片 -->
					<div class="w-full   bg-white  rounded-[10px]    p-[30px]  ">
						<!-- 文案 -->
						<div class="text-[30px] font-medium  flex flex-nowrap">
							<div class="text-[#1C91FF] font-medium leading-[24px]  pr-2">
								{{ brain.title }}
							</div>
							<div class="w-[6px] h-[6px]  bg-black leading-[24px]  rounded-lg mt-[12px] mb-[12px]">
							</div>
							<div class="text-black font-medium leading-[24px]  pl-2">
								{{ brain.des }}
							</div>
						</div>
						<!-- 中间展示 -->
						<div v-if="brain.id === 'offLine'" class=" flex flex-wrap flex-col justify-between px-[36px]">
							<div class=" flex flex-wrap justify-between  mt-[45px] text-white font-bold  pb-6 ">
								<div v-for="title in brain.offLineData?.text" :key="title"
									class="text-[33px] text-center cardBg w-[100px] h-[100px] rounded-full  font-bold  leading-[70px]
									flex justify-center items-center  
									">
									{{ title }}
								</div>
							</div>
							<div class="text-[24px] text-[#999] text-center">
								{{ brain.offLineData?.advert || '' }}
							</div>
						</div>

						<!-- 中间展示 -->
						<div v-else class=" flex flex-wrap flex-col justify-between ">
							<div class=" flex flex-wrap justify-between mt-[45px]   pb-5 text-white font-bold ">
								<div v-for="onLine in brain.onLineData?.text" :key="onLine.title"
									class=" text-center cardBg w-[185px] h-[100px]   mb-[10px] rounded-[6px] p-[11px]
									flex justify-center items-center flex-wrap
									">
									<div class="text-[30px]  font-bold leading-[18px] pb-[6px]">
										{{ onLine.title }}
									</div>
									<div class="text-[22px] font-medium leading-[14px]">
										{{ onLine.des }}
									</div>
								</div>
							</div>
							<div class="text-center text-[24px]">
								<span v-for="(adverts, index) in brain.onLineData?.advert" :key="adverts"
									:style="{ color: (index === 1 || index === 3) ? '#4285F4' : '#999999' }">
									{{ adverts }}
								</span>
							</div>

						</div>




					</div>
					<!-- <div class=" flex flex-wrap items-center">
						<div class="w-[53px] h-[53px] brainIcons" :style="{ backgroundImage: `url(${brain.icon})` }">
						</div>
						<div class="text-[20px] font-medium">
							{{ brain.title }}
						</div>
					</div>
					<div class="brainCardsDes">
						{{ brain.des }}
					</div> -->
				</div>
			</div>

		</div>

	</div>
</template>
<script setup>
import {getVw} from '@/utils/getVw'

import TitleDes from '@/components/title-des/index.vue';

const brainData = [
	{
		title: '线上',
		des: '产业大脑平台',

	 
		id: 'offLine',
		offLineData: {
			text: ['研', '判', '招', '管'],
			advert: '产业发展精准研判·招商信息实时推送',
		}
	},
	{
		title: '线下',
		des: '产业运营团队',
		 
		id: 'onLine',
		onLineData: {
			text: [{
				title: '大湾区',
				des: '产业运营中心',
			}, {
				title: '长三角',
				des: '产业运营中心',
			}, {
				title: '京津冀',
				des: '产业运营中心',
			}],
			advert: ['触达', '200+', '园区、', '20000+', '企业'],
		}
	},
]
</script>
<style lang="less" scoped>
.onLineAndOffLinePart {
	background: linear-gradient(120deg, #0A69F6, #8CEBFF);

	.bg-left-right {
		width: 100%;
		height: 100%;
		background: white;

		background:
			// left bottom 18px / contain no-repeat url("@/assets/imgs/homes/industry_bg.png"),
			center / contain no-repeat url("@/assets/imgs/homes/onLine_bg.png");
		// white right 358px top 144px/ contain no-repeat url("@/assets/imgs/homes/industry_4.png");
		background-size: 105% 514px;
	}

	.brainIcons {
		// margin-right: 10px;
		background: center / contain no-repeat;
	}

	.cardBg {

		background: linear-gradient(0deg, #0A69F6, #8CEBFF);
	}
}
</style>
