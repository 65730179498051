<template>
	<template v-if="flexCol">
		<IndexCol :showInfo="showInfo" :blueBg="showInfo.blueBg" />
	</template>
	<template v-else>
		<div class="w-full h-[517px]  flex justify-center items-center flex-nowrap "
			:class="{ bgImg: props.showBg, 'bg-blue': showInfo.blueBg, 'flex-row-reverse [&>*:last-child]:mr-[78px] ': isRevert, '[&>*:first-child]:mr-[78px] ': !isRevert }"
			:style="{ backgroundImage: `url(${showInfo.backBg})`, backgroundSize: `${showInfo.size}`, 'backgroundPosition': `${showInfo.position}` }">
			<div class=" flex flex-col w-[440px]  items-start">
				<div class="text-[40px] text-[#333]">
					<TitleDes :beforeTitle="showInfo.index" noSpecialCharacter="true" :title="showInfo.title" lineLeft="0px" />
				</div>
				<div class="text-12PX text-[#666] mt-[64px]">
					{{ showInfo.des }}
				</div>
			</div>
			<div class="w-[675px] h-[334px] back_img" :style="{ backgroundImage: `url(${showInfo.img})` }"> </div>
		</div>
	</template>
</template>
<script setup>
import TitleDes from '@/components/title-des/index.vue';
import IndexCol from './indexCol.vue'
import { defineProps, ref } from 'vue'
const props = defineProps(['showInfo', 'isRevert', 'showBg', 'flexCol'])

</script>
<style scoped>
.bgImg {
	background-repeat: no-repeat !important;
}

.bg-blue {
	background: #F2F7FC
}



.back_img {
	background: center / contain no-repeat;

}
</style>