<template>
	<div class=" w-full flex  flex-wrap  font-medium "
		:style="{ marginTop: top, marginBottom: bottom, color: textColor || 'black' }">
		<div class=' w-full text-[36px]  leading-[40px] font-medium' :class="lineLeft ? 'text-left' : 'text-center'">
			<span v-if="beforeTitle">
				<span v-if="noSpecialCharacter"
					class="text-[#0A69F6] text-[80px]  pr-[10px] fontFamily align-bottom mb-[7px] inline-block">{{
						beforeTitle }}</span>
				<span v-else class="mr-[-16px]">「<span class="beforeTitle"> {{ beforeTitle }}</span>」</span>
			</span>{{ props?.title }}
		</div>
		<LineDom class="lineDom" :bgColor='lineColor ? lineColor : defaultColor' :lineTop="lineTop ? lineTop :  getVw(35)"
			:lineLeft="lineLeft ? lineLeft : 'auto'" />
	</div>
</template>
<script setup>
import {getVw} from '@/utils/getVw'
import LineDom from './title-line.vue'
import { defineProps, ref } from 'vue'
const props = defineProps(['title', 'top', 'bottom', 'textColor', 'lineColor', 'lineTop', 'lineLeft', 'beforeTitle', 'noSpecialCharacter']);
const defaultColor = ref('#4285F4')
</script>

<style scoped>
.lineDom {
	margin: auto
}

.beforeTitle,
.fontFamily {
	font-family: YouSheBiaoTiHei !important;
}
</style>