<template>
	<div class="w-full h-[438px] backBg" :style="{ backgroundImage: `url(${props.backBg})` }">
		<div class="flex flex-col  w-full pt-[80px]  h-[438px] text-white flex-wrap justify-center text-center">
			<div class="text-[54px] leading-[60px] slidesTitle "> {{ props.title }}</div>
			<div class="text-[30px] mt-[45px]">
				{{ props.des }}
			</div>
		</div>
	</div>
</template>
<script setup>
import { defineProps } from 'vue'
const props = defineProps(['backBg', 'title', 'des'])
</script>
<style scoped>
.backBg {
	background: bottom / contain no-repeat;

}
</style>
