

<template>
  <div class="flex flex-col  w-full bg-white text-black flex-wrap min-h-screen">

    <!-- 导航条 -->
    <div class=' w-[100%] h-[80px]   flex flex-wrap  fixed z-40' :style="{ background: backgroundColor }">
      <div :class='colorChange ? "colorChange " : ""'
        class="  w-[100%] h-[80px] px-[35px]  flex flex-none  justify-between items-center text-[rgba(255, 255, 255, 0.8)]  z-10 ">

        <div>
          <div class="w-[172px] h-[57px]  bg-contain  bg-center bg-no-repeat  logo" @touchend="jump('/homePage')"> </div>
        </div>
        <div class="flex justify-center items-center">
          <div class="chatButton w-[165px] h-[63px] leading-[63px]   rounded-[120px] bg-inherit text-center border border-white 
           text-[28px]  text-white    mr-[30px]" @click="showDialog">
            申请体验
          </div>
          <!-- @click="showSelector" -->
          <span class="wapNav text-[45px] text-white" :class="isHidden ? 'clicked' : 'notClicked'">

            <van-dropdown-menu>
              <van-dropdown-item title='' ref="itemRef" @open="showSelector" @close="closeSelector">
                <!-- v-if="isHidden"  -->
                <!--    -->
                <div class=' selector   '>
                  <div class="bg-white  w-[100%]   selectorItem  ">
                    <div @touchend="jump('/homePage')"
                      class="h-[100px] text-[30px] text-[#333] leading-[100px] px-[30px]">
                      首页
                    </div>
                    <div>
                      <van-collapse v-model:model-value="activeNames">
                        <van-collapse-item title="产品方案" name="1">
                          <div @click="jump('/product/goldenBrain')"
                            class="h-[100px] text-[28px] text-[#333] leading-[100px] px-[30px] bg-[#F2F7FC]">产业金脑</div>
                          <div @click="jump('/product/industryResearch')"
                            class="h-[100px] text-[28px] text-[#333] leading-[100px] px-[30px] bg-[#F2F7FC]">数字产研</div>
                          <div @click="jump('/product/attractInvestment')"
                            class="h-[100px] text-[28px] text-[#333] leading-[100px] px-[30px] bg-[#F2F7FC]">数智招商</div>
                        </van-collapse-item>
                        <van-collapse-item title="产业报告" name="2">
                          <div @click="jump('/report/1')"
                            class="h-[100px] text-[28px] text-[#333] leading-[100px] px-[30px] bg-[#F2F7FC]">产业洞察</div>
                          <div @click="jump('/report/2')"
                            class="h-[100px] text-[28px] text-[#333] leading-[100px] px-[30px] bg-[#F2F7FC]">专题报告</div>
                          <div @click="jump('/report/3')"
                            class="h-[100px] text-[28px] text-[#333] leading-[100px] px-[30px] bg-[#F2F7FC]">智能研报</div>
                        </van-collapse-item>
                      </van-collapse>
                    </div>
                    <div @click="jump('/aboutUs/01')" class="h-[100px] text-[30px] text-[#333] leading-[100px] px-[30px]">
                      关于我们
                    </div>
                  </div>
                </div>
              </van-dropdown-item>

            </van-dropdown-menu>
          </span>
        </div>
      </div>

    </div>
    <!-- </div> -->
    <div class="flex-[1]  w-full  ">
      <div class=""> <router-view></router-view></div>
      <div v-if="router.currentRoute.value.matched[0]?.path !== '/consultUsForm/:type'">
        <Footer @jump='jump' />
      </div>
    </div>
    <!-- <div v-if="isShowValue" class="bg-black/70 fixed top-0 left-0 z-40 w-full h-full overscroll-contain">
      <ConsultUsForm @close='closeDialog' state="experience" />
    </div> -->
  </div>
</template>
<script    setup>
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import Footer from './footer/index.vue'
// import ConsultUsForm from '@/components/consultUsForm/index.vue'
const router = useRouter();

const currentRouter = ref('/homePage')
const backgroundColor = ref('transparent')
const colorChange = ref(false)
const isHomeRouter = ref(true)
const activeNames = ref([]);
const itemRef = ref(null);
const isHidden = ref(false);
// const isShowValue = ref(false);

watch(
  () => router,
  (newValue) => {

    let value = newValue.currentRoute.value.fullPath;
    isHomeRouter.value = value === '/' || value === '/homePage' || value === '/#/homePage'
    currentRouter.value = value === '/' || value === '/#/homePage' ? '/homePage' : value
    if (value !== '/' && value !== '/homePage' && value !== '/#/homePage') {
      backgroundColor.value = '#fff'
      colorChange.value = true;
    } else {
      backgroundColor.value = "transparent";
      colorChange.value = false;
    }
  },
  { immediate: true, deep: true }
);
onMounted(() => {
  console.log("proce==>", router.currentRoute.value,import.meta.env, import.meta.env.VITE_APP_API);
  window.addEventListener("scroll", handleScroll);
});
// beforeDestroy(() => {
//   window.removeEventListener("scroll", handleScroll);
// });
const handleScroll = () => {
  let value = router.currentRoute.value.fullPath
  let scrollTop =
    document.documentElement.scrollTop ||
    document.body.scrollTop;
  if ((value === '/') || (value === '/homePage') || (value === '/#/homePage')) {

    //设置背景颜色的透明度
    if (scrollTop && !(scrollTop / 80 > 2) || (backgroundColor.value !== `rgba(255, 255, 255,1)` && scrollTop > 80)) {
      backgroundColor.value = `rgba(255, 255, 255,${scrollTop / 80})`;
      colorChange.value = true;


    } else if (scrollTop == 0) {
      backgroundColor.value = "transparent";
      colorChange.value = false;
    }
  }

}

const jump = (path) => {
  if (path === router.currentRoute.value.fullPath) {
    isHidden.value = false
    itemRef.value.toggle();
    return
  }
  if (path !== '/' && path !== '/homePage' && (path !== '/#/homePage')) {
    backgroundColor.value = 'transparent'
    colorChange.value = false;
  }
  isHidden.value = false
  itemRef.value.toggle();
  router.push({
    path: path
  })
}
const showSelector = () => {
  isHidden.value = true
}
const closeSelector = () => {

  // console.log('close selector')
  isHidden.value = false
}


const showDialog = () => {
  router.push({
    path: '/consultUsForm/experience'
  })
  // isShowValue.value = true
}
// const closeDialog = () => {
//   isShowValue.value = false
// }
</script>

<style lang="less" scoped>
@import './index.less';

.selector {

  // height: calc(100vh - 80px);
  .selectorItem {
    border-radius: 0px 0px 10px 10px;
  }
}

.dropdownText:global(.el-dropdown) {
  line-height: 28px !important;
  height: 28px;
}

.dropdownText:global(.el-dropdown .el-tooltip__trigger:focus-visible) {
  outline: 0px !important;
}

.dropdownText:global(.el-popper.is-light .el-popper__arrow::before) {
  display: none;
}
</style>
<style lang="less" >
.colorChange {
  .notClicked {
    .van-dropdown-menu__bar {
      background: center / contain no-repeat url(../../assets/imgs/homes/navbar-color.png);
    }
  }

  .clicked {
    .van-dropdown-menu__bar {
      background: center / contain no-repeat url(../../assets/imgs/homes/close.png) !important;
    }
  }
}

.clicked {
  .van-dropdown-menu__bar {
    background: center / contain no-repeat url(../../assets/imgs/homes/close-white.png) !important;
  }

}

.notClicked,
.wapNav {
  .van-popup {
    border-radius: 0px 0px 10px 10px;
  }

  .van-dropdown-menu__bar {
    box-shadow: 0 0 0;
    width: 30px;
    height: 80px;
    background: center / contain no-repeat url(../../assets/imgs/homes/navbar.png);

    .van-dropdown-menu__title {
      display: none;
    }
  }
}

.selector {

  .van-cell {
    line-height: 100px;
    padding: 0 30px;
    height: 100px;
    font-size: 30px;
    color: #333333;

    .van-badge__wrapper {
      line-height: 100px;
      height: 100px;
      font-size: 30px;
    }
  }

  .van-collapse-item__content {
    padding: 0;
  }
}
</style>
0 