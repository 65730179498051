<template>
	<div class="view-container">
		<Top :backBg="img" title="数智招商" des="赋能区域产业招商引资提质增效" />
		<!-- 全面赋能招商业务 -->
		<div class="w-full pb-[20px]  bg-white ">
			<div class='bg-left-right  flex flex-col  content-start  '>
				<div class="h-[80px] mt-[70px] w-full">
					<TitleDes title="全面赋能招商业务" />
				</div>
				<div class="w-full text-center text-[26px] px-[62px] text-[#666] mb-[95px] mt-[5px]">提供线上招商系统、线下委托联系的融合服务模式，全方位为区域产业招商赋能。
				</div>

				<div class="w-full flex  justify-center">
					<div class=" w-[750px]   flex flex-wrap   justify-center  grid-cols-2 grid-flow-row gap-10  ">
						<GridComponents :gridData="gridData" />
					</div>
				</div>
			</div>
		</div>
		<!-- 大数据算法模型提升招商效率 -->
		<div class="w-full    ">
			<div class='bg-left-right  flex flex-col  content-start  '>
				<div class="h-[80px] mt-[70px] w-full">
					<TitleDes title="大数据算法模型提升招商效率" />
				</div>
				<div class="w-full text-center text-12PX text-[#666] mb-[25px] mt-[5px]">基于招商业务特征的算法模型，助力实现精准招商。</div>

				<div class="mt-[62px] w-full">
					<productIntroduction cardWidth='w-[160px] ' iconWidth='w-[70px] h-[70px]' :listData="businessScenario" />
				</div>
			</div>
		</div>
		<IntroductionAndImg showBg="true" :showInfo="showInfo.show_1" flexCol="true"/>
		<IntroductionAndImg showBg="true" isRevert='true' :showInfo="showInfo.show_2" flexCol="true" />
	</div>
</template>
<script setup>
import { onMounted, ref, defineComponent } from 'vue'
import TitleDes from '@/components/title-des/index.vue';
import productIntroduction from '@/components/productIntroduction/index.vue'
import GridComponents from '@/components/gridComponents/index.vue'
import Top from '@/components/top-img/index.vue'
import img from '@/assets/imgs/product/shuzhi_top.png'
import bgImg1 from '@/assets/imgs/product/shuzhi_in1.png'
import bgImg2 from '@/assets/imgs/product/shuzhi_in2.png'
import bgImg3 from '@/assets/imgs/product/shuzhi_bgEn1.png'
import bgImg4 from '@/assets/imgs/product/shuzhi_bgEn2.png'
import icons1 from '@/assets/imgs/product/shuzhi_icons1.png'
import icons2 from '@/assets/imgs/product/shuzhi_icons2.png'
import icons3 from '@/assets/imgs/product/shuzhi_icons3.png'
import icons4 from '@/assets/imgs/product/shuzhi_icons4.png'
import icons5 from '@/assets/imgs/product/shuzhi_icons5.png'
import icons6 from '@/assets/imgs/product/shuzhi_icons6.png'
import icon1 from '@/assets/imgs/product/shuzhi_Icon1.png'
import icon2 from '@/assets/imgs/product/shuzhi_view2.png'
const percentage = ref(10)
const businessScenario = [
	{
		id: '01',
		title: '亲商招商',
		des: '捕捉本地人脉关系,招引游子回乡创业',
		icon: icons1
	}, {
		id: '02',
		title: '资源招商',
		des: '识别本地优势自然与人力资源，精选匹配产业链资源需求企业',
		icon: icons2
	}, {
		id: '03',
		title: '链主招商',
		des: '基于本地产业链主企业，推荐供应链及相关配套企业',
		icon: icons3
	}, {
		id: '04',
		title: '政策招商',
		des: '解读各地产业政策，挖掘政策引导与疏解的高价值企业',
		icon: icons4
	},
	{
		id: '01',
		title: '舆情招商',
		des: '实时监测产业企业动态，分析挖掘潜在招商机会',
		icon: icons5
	}, {
		id: '01',
		title: 'AI+招商',
		des: '基于对海量历史投资数据训练学习，预测企业未来投资意向',
		icon: icons6
	}
]
const showInfo =
{
	show_1: {
		index: null,
		title: '数智招商雷达引擎',
		des: '实时追踪产业链企业、技术、产品动态，智能匹配本地现状，捕获潜在招商线索',
		img: bgImg1,
		backBg: bgImg3,
		size: '40.3rem 7.68rem',
		position: '21rem 7.87rem',
		blueBg: true
		// place: 'left'
	}, show_2: {
		index: null,
		title: '一屏察秋毫',
		des: '线索信息共享，实时跟踪进度，实现招商全流程精细化管理',
		img: bgImg2,
		backBg: bgImg4,
		size: '41rem 13.56rem',
		position: 'right 11.87rem top 11rem',
		// place:'right'

	},
}
const gridData = [
	{
		title: '数智招商系统',
		icon: icon1,
		id: '001',
		list: [
			{ title: '招商地图' },
			{ title: '招商雷达' },
			{ title: '招商智管' },
			{ title: '360洞察' },
			{ title: '招商策略' },
			{ title: '……' },
		]
	},
	{
		title: '招商运营服务',
		icon: icon2,
		id: '002',
		list: [
			{ title: '委托招商' },
			{ title: '产业规划' },
			{ title: '产业报告' },
			{ title: '产业投资' },
			{ title: '……' },
		]
	}
]
</script>
<style scoped>
.title {
	font-family: "YouSheBiaoTiHei" !important;
}

.blue_bg {
	background: #F2F7FC;
}

.bg-left-right {
	/* width: 100%;
	height: 100%; */
	background:white;
		/* left -23px top 139px / contain no-repeat url("@/assets/imgs/homes/industry_bg.png"); */
	/* center / contain no-repeat url("@/assets/imgs/homes/brain_bg3.png"), */
	/* #F2F7FC right 0 bottom / contain no-repeat url("@/assets/imgs/homes/brain_bg2.png"); */
	/* background-size: 360px 372px; */
}
</style>
