<template>
  <div class="view-container bg-white">
    <Top :backBg="img" title="产业报告" des="" />
    <div class="">
      <div class=" flex items-center justify-center mt-[60px] mb-[10px]">
        <div v-for="(item, index) in btnData" :key="item.id" class=" w-[170px] h-[70px] leading-[70px] text-center border border-[#0A69F6]/50 text-[#0A69F6] 
          rounded text-[30px]"
          :class="{ 'mx-[30px]': index === 1, 'active': active === item.id }" @click="change(item)">
          {{ item.title }}
        </div>
      </div>
      <div class="w-[690px] flex flex-wrap justify-center  mx-[auto] ">
        <!-- <div class=" w-full text-[#666]">
          共<span class="text-[#0A69F6]">{{ listNumber }}</span> 篇产业洞察报告
        </div> -->
        <!-- -->
        <div v-if="listNumber > 0" class="w-full  pt-[35px] ">
          <div class="pb-[40px]    ">
            <ReportList :reportData='mockData' />
          </div>

          <!-- <div class="pb-[66px] pr-[10px]  float-right">
            <van-pagination v-model="currentPage" :total-items="listNumber" 
            :show-page-size="3"
            force-ellipses
            @change="changePage"
             />
          </div> -->

        </div>
        <div v-else class="w-full  pb-[110px] pt-[70px] ">
          <van-empty 
          :image='empty'
          :image-size="[135, 125]"
          description="暂无数据" />
      
        </div>
      </div>

    </div>
  </div>
</template>
 
<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { getReportList,perFix } from "../../api/index";
import { Empty } from 'vant';
import Top from '@/components/top-img/index.vue'
import ReportList from '@/components/reports/index.vue';
import img from '@/assets/imgs/report/report_top1.png';
import book from '@/assets/imgs/report/report_book.png';
import empty from '@/assets/empty.png'
import axios from 'axios'
const router = useRouter();
const active = ref('1')
const listNumber = ref(0)
const btnData = [
  {
    id: '1',
    title: '产业洞察',
  },
  {
    id: '2',
    title: '专题报告',
  },
  {
    id: '3',
    title: '智能研报',
  }
]
const mockData = ref()
const currentPage=ref(1)
watch(
  () => router.currentRoute.value.params.id,
  (newValue, oldValue) => {
    active.value = newValue
    console.log(11,  active.value)

    getReportLists()
  })
  onMounted(() => {
    active.value = router.currentRoute.value.params.id
    console.log(  active.value)

  getReportLists()
},)
const getReportLists = () => {
    axios.post(`${perFix}/dpar/industryReport/pageByAdmin`, {
      "pageNum": currentPage.value,
      "pageSize":  '9999',
      "type": active.value,
    })
      .then(function (response) {
        if (response.data.code === 'SUCCESS') {
          let { result, } = response.data
          mockData.value = result.records
          listNumber.value = result.totalNum

        }
      })
}
const changePage=(val)=>{
  // console.log(1)
  currentPage.value = val
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  getReportLists()
}
const change = (data) => {
  active.value = data.id
  router.push({
    path: `/report/${data.id}`,
  })
}
</script>
<style scoped> .active {
   border: 0px;
   background: #0A69F6;
   color: white
 }
</style>
<style> 
.van-empty__description{
font-size: 28px;
}
.van-empty{
  padding:8.533vw 0;
}
</style>
