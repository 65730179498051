<template>
	<div class="view-container">
		<Top :backBg="img" title="产业金脑" des="赋能区域产业高质量发展" />
		<!--赋能产业高质量发展  -->
		<div class="brainPart w-full h-[670px]  ">
			<div class='bg-left-right  flex flex-col  content-start  '>
				<div class="h-[80px] mt-[70px] w-full">
					<TitleDes beforeTitle="12345" title="赋能产业高质量发展" />
				</div>
				<div class="mt-[30px] w-full">
					<productIntroductionSpecial :listData="businessScenario" showIndex="true" />
				</div>
			</div>
		</div>
		<!-- 1套数字底座 -->
		<IntroductionAndImg showBg="'true'" :showInfo="showInfo.show_1" flexCol="true"  />
		<IntroductionAndImg :showInfo="showInfo.show_2" flexCol="true" />
		<IntroductionAndImg :showInfo="showInfo.show_3"  flexCol="true" />
		<IntroductionAndImg isRevert='true' :showInfo="showInfo.show_4"  flexCol="true" />
		<IntroductionAndImg :showInfo="showInfo.show_5"  flexCol="true" />

	</div>
</template>
<script setup>
import { onMounted, ref, defineComponent } from 'vue'
import TitleDes from '@/components/title-des/index.vue';
import productIntroductionSpecial from '@/components/productIntroductionSpecial/index.vue'
import IntroductionAndImg from '@/components/introductionAndImg/index.vue'
import Top from '@/components/top-img/index.vue'
import img from '@/assets/imgs/product/产业金脑.png'
import icon1 from '@/assets/imgs/product/jinnao_icon1.png'
import icon2 from '@/assets/imgs/product/jinnao_icon2.png'
import icon3 from '@/assets/imgs/product/jinnao_icon3.png'
import icon4 from '@/assets/imgs/product/jinnao_icon4.png'
import icon5 from '@/assets/imgs/product/jinnao_icon5.png'
import bgImg1 from '@/assets/imgs/product/jinnao_img1.png'
import bgImg2 from '@/assets/imgs/product/jinnao_img2.png'
import bgImg3 from '@/assets/imgs/product/jinnao_img3.png'
import bgImg4 from '@/assets/imgs/product/jinnao_img4.png'
import bgImg5 from '@/assets/imgs/product/jinnao_img5.png'
import bgImg6 from '@/assets/imgs/product/jinnao_bg1.png'
import bgImg7 from "@/assets/imgs/homes/industry_bg.png"
const bg_img = bgImg1
const percentage = ref(10)
const showInfo =
{
	show_1: {
		index: '1',
		title: '套数字底座',
		des: '利用大数据+数据挖掘技术，联通产业相关的企业、机构、人才、技术、产品、资金等数据资源，构成产业大数据数字底座，有效释放数据要素价值。',
		img: bgImg1,
		backBg: bgImg7,
		size: '360px 372px',
		position: '0 200%',
	},
	show_2: {
		index: '2',
		title: '种服务模式',
		des: '打通产业数字决策平台与遍布全国的产业运营服务网络体系，提供线上分析研判、线下委托互动的融合服务模式，全方位为区域产业发展赋能。',
		img: bgImg2,
		backBg: null,
		blueBg: true
	},
	show_3: {
		index: '3',
		title: '个数字终端',
		des: '打造大屏端+PC端+移动端3个数字终端，驾驶舱全景大屏总览“产业家底”，PC端满足日常研、判、招、管需求，移动端提供随身便携的工具支持，全方位适配产业人员多种工作场景，提升工作效率。',
		img: bgImg3,
		backBg: null,
	}, show_4: {
		index: '4',
		title: '大数据赋能',
		des: '构建产业洞察、产业全景、产业招商、招商管理“4大”数据智能应用。',
		img: bgImg4,
		backBg: null,
		blueBg: true
	}, show_5: {
		index: '5',
		title: '类场景应用',
		des: '赋能产业研究、产业决策、产业招商、产业运营、产业投资等“5类”场景应用， 以数字化、共享化、智能化产业平台+网格化、专业化线下运营服务体系，助力提升区域产业治理水平。',
		img: bgImg5,
		backBg: null,
	}
}

const businessScenario = [
	{
		id: '01',
		title: '套数据底座',
		des: null,
		icon: icon1,
	}, {
		id: '02',
		title: '种服务模式',
		des: null,
		icon: icon2
	}, {
		id: '03',
		title: '个数字终端',
		des: null,
		icon: icon3,
	}, {
		id: '04',
		title: '大数据赋能',
		des: null,
		icon: icon4,
	}, {
		id: '05',
		title: '类场景应用',
		des: null,
		icon: icon5,
	}
]
</script>
<style scoped>
@import 'swiper/swiper-bundle.css';
@import 'swiper/css/pagination';




.title {
	font-family: "YouSheBiaoTiHei" !important;
}

.bg-left-right {
	width: 100%;
	height: 100%;
	background:white;
		/* left 44px top 139px / contain no-repeat url("@/assets/imgs/homes/brain_bg1.png"), */
		/* center / contain no-repeat url("@/assets/imgs/homes/brain_bg3.png"), */
		/* #F2F7FC right 0 bottom / contain no-repeat url("@/assets/imgs/homes/brain_bg2.png"); */
	/* background-size: 215px 192px, 279px 372px; */
}
</style>
