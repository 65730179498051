<template>
	<div class="w-[100%] grid grid-cols-2 grid-flow-row gap-4   ">


		<div v-for="reportList in reportData" :key="reportList.id"
			class="imgHover reportList w-[330px]  h-[425px] bg-white rounded-[10px]"
			@click="showDialog(reportList)"
			>
			<div class="showCard bg-[#F5F9FF] w-full h-[290px] flex justify-center items-center  text-white">
				<!--   -->
				<div class="showImg w-[177px] h-[228px] bg-[url(@/assets/imgs/report/report_book.png)]
				bg-bottom  bg-no-repeat bg-cover  drop-shadow-xl ">
				</div>
				<div class="showIcon hidden w-[145px]  flex-wrap justify-center items-center">
					<div class=" w-[103px] h-[103px] bg-[url(@/assets/imgs/report/report_icon.png)]
				bg-bottom  bg-no-repeat bg-contain ">
					</div>
					<div class="text-[24px] mt-[35px] ">
						获取报告权限
					</div>

				</div>


			</div>
			<div class="flex flex-wrap justify-start items-center pt-[34px] pl-[24px] pb-[40px]">
				<div class=" w-full text-[24px] text-left ">
					{{ reportList.title }}
				</div>
				<div class=" text-[#999] w-full text-[18px] text-left ">
					{{ reportList.time }}
				</div>
			</div>


		</div>
		<!-- <div v-if="show"  class="bg-black/70 fixed top-0 left-0 z-40 w-full h-full overscroll-contain">

					 <ConsultUsForm   @close='closeDialog' state="report" :reportName="reportName" :reportId="reportId" />
		</div> -->
	</div>
</template>
<script setup>
import { ref, defineProps } from 'vue';
// import ConsultUsForm from '@/components/consultUsForm/index.vue';
import { useRouter } from 'vue-router'
const props = defineProps(['reportData']);
const router = useRouter();
// const show = ref(false);
const reportName = ref('')
const reportId = ref('')
const showDialog=(reportList)=>{
	sessionStorage.setItem('reportId',reportList.id)
	sessionStorage.setItem('reportName',reportList.title)
 
	router.push({
    path:'/consultUsForm/report'
  })
	//  show.value=true
	//  reportName.value = reportList.title
	//  reportId.value = reportList.id
}
// const closeDialog = () => {
// 	show.value= false
// }
</script>
<style lang="less" scoped>
 

.reportList {
	box-shadow: 0px 0px 51px 0px #EBEBEB;
}
</style>