// https://router.vuejs.org/zh/
import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 导入路由组件
import HomePage from "@/pages/home/index.vue"
import AboutUs from "@/pages/aboutUs/index.vue"
import Report from "@/pages/report/index.vue"
import GoldenBrain from "@/pages/product/goldenBrain/index.vue"
import IndustryResearch from "@/pages/product/industryResearch/index.vue"
import AttractInvestment from "@/pages/product/attractInvestments/index.vue"
import ConsultUsForm from '@/pages/consultUsForm/index.vue'
 

NProgress.configure({ showSpinner: true, parent: '#app' })

// 定义路由，每个路由都需要映射到一个组件
const routes = [{
  path: '/',
  component: HomePage,
  name: "HomePage",
  children:null
},
{
  path: '/product',
  name: "Product",
  // component: Product,
  children: [
    {
      path: 'goldenBrain',
      component: GoldenBrain,
    }, {
      path: 'industryResearch',
      component: IndustryResearch,
    },
     {
      path: 'attractInvestment',
      component: AttractInvestment,
    },
  ]

}, {
  path: '/homePage',
  component: HomePage,
  children:null
}, {
  path: '/aboutUs/:id',
  component: AboutUs,
  name: 'AboutUs',
  children:null
}, {
  path: '/report/:id',
  component: Report,
  name: 'Report',
  children:null
},
{
  path: '/consultUsForm/:type',
  component: ConsultUsForm,
  children:null
},
]
// 创建路由实例并传递 `routes` 配置
const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes,
})

router.beforeEach((_to, _from, next) => {
  NProgress.start() // start progress bar
  next()
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})

// 导出路由实例，并在 `main.ts` 挂载
export default router
