<template>
	<div class="brainPart w-full  h-[585px]   ">
		<div class='bg-left-right  flex flex-wrap flex-col  '>
			<TitleDes title="艾瑞数云产业大脑" :top="getVw(70)" :bottom="getVw(45)" />
			<div class='w-full h-[310px] overflow-x-scroll '>
			<div class='w-[1800px]  h-[300px]  scrollBar    px-[30px] '>
				<div v-for="brain in brainData" :key="brain.id"
					class=' w-[550px] min-h-[300px] brainCards  mr-[30px] bg-white rounded-[10px] float-left'>
					<div class=" flex flex-nowrap items-center">
						<div class="w-[75px] h-[75px] brainIcons" :style="{ backgroundImage: `url(${brain?.icon})` }">
						</div>
						<div class="text-[30px] font-medium">
							{{ brain.title }}
						</div>
					</div>
					<div class="brainCardsDes text-[24px] text-[#999]">
						{{ brain.des }}
					</div>
				</div>
			</div>
			</div>


		</div>
	</div>
</template>
<script setup>
import {getVw} from '@/utils/getVw'
import TitleDes from '@/components/title-des/index.vue';
import brain_1 from '@/assets/imgs/homes/brain_1.png';
import brain_2 from '@/assets/imgs/homes/brain_2.png';
import brain_3 from '@/assets/imgs/homes/brain_3.png';
const brainData = [
	{
		title: '创新产业精细管理新抓手',
		des: '整合内外部产业数据资产，加强数据分析与应用，推动产业管理精细化、企业服务精准化，促进产业经济高速、健康发展。',
		icon: brain_1,
		id: 'brain_1'
	}, {
		title: '加速产业转型升级新引擎',
		des: '提升产业发展运行监测的精确性和实效性，为优化产业结构及全面落实资源要素差异化配置提供科学依据，促进产业发展转型升级。',
		icon: brain_2,
		id: 'brain_2'
	}, {
		title: '推进产业招商提质增效新工具',
		des: '围绕产业链进行精准招商，确定强链、固链、补链招引方向，实现产业集聚发展，提升招商工作便利性，为招商业务提质增效赋能。',
		icon: brain_3,
		id: 'brain_3'
	}
]
</script>
<style lang="less" scoped>
.brainPart {
	.bg-left-right {
		width: 100%;
		height: 100%;
		background:
			// left 44px top 0 / contain no-repeat url("@/assets/imgs/homes/brain_bg1.png"),
			rgba(242, 247, 252, 1)	center / contain no-repeat url("@/assets/imgs/homes/brain_bg3.png");
			// #F2F7FC right 0 / contain no-repeat url("@/assets/imgs/homes/brain_bg2.png");
		background-size:   100%  510px;
	}

	.brainCards {
		padding: 50px  30px 30px 30px;

		.brainCardsDes {
			margin-top: 12px;
		 
		}

		.brainIcons {
			margin-right: 15px;
			background: center / contain no-repeat;
		}
	}

}
::-webkit-scrollbar {
    // display: none;//隐藏滚动条
		height: 0px;
   width: 6px;
  }
 
// 滚动条轨道
::-webkit-scrollbar-track {
   background-color: #ccc;
}
// 滚动条滑块
::-webkit-scrollbar-thumb {
   background-color: rgba(#ccc, .6);
}
</style>
