<template>
	<div class="industryPart w-full ">
		<div class='bg-left-right   mb-5  '>
			<div class="industryText flex flex-wrap  w-full   justify-center ">
				<div class="titleDes">
					<TitleDes title="三位一体产业赋能体系" :top="getVw(70)" :bottom="getVw(45)"  />
				</div>
				<div>
					<div v-for="industry in  industryData " class='industryData w-[690px] h-[160px] flex flex-wrap  align-middle items-center
				 shadow-lg
					'
					:style="{backgroundImage:`url( ${industry.bg})`}"
						:key="industry.id">
						<div class="w-[72px] h-[72px] industryIcons" :style="{ backgroundImage: `url(${industry.icon})` }">
						</div>
						<div class="h-[80px] flex flex-wrap flex-col justify-between  ">
							<div class="font-medium text-[28px] text-[#333333]">
								{{ industry.title }}
							</div>
							<div class="text-[23px] text-[#666666]">
								{{ industry.des }}
							</div>
						</div>

					</div>

				</div>
			</div>

		</div>

	</div>
</template>
<script setup>
import {getVw} from '@/utils/getVw'
import TitleDes from '@/components/title-des/index.vue';
import industry_1 from '@/assets/imgs/homes/industry_1.png'
import industry_2 from '@/assets/imgs/homes/industry_2.png'
import industry_3 from '@/assets/imgs/homes/industry_3.png'
import industry_4 from '@/assets/imgs/homes/industry_4.png'
import industry_5 from '@/assets/imgs/homes/industry_5.png'
import industry_6 from '@/assets/imgs/homes/industry_6.png'

const industryData = [
	{
		id: '01',
		title: '产业研究',
		des: '以数字分析产业',
		icon: industry_1,
		bg:industry_4
	}, {
		id: '02',
		title: '产业服务',
		des: '以数智支持运营',
		icon: industry_2,
		bg:industry_5

	}, {
		id: '03',
		title: '产业投资',
		des: '以数资赋能发展',
		icon: industry_3,
		bg:industry_6

	},
]
</script>
<style lang="less" scoped>
.industryPart {
	// background:  white;

	.bg-left-right {
		width: 100%;
		height: 100%;
		background:
		white 	left top 75px / contain no-repeat url("@/assets/imgs/homes/industry_bg.png");
			// center / contain no-repeat url("@/assets/imgs/homes/brain_bg3.png"),
			// white right 358px top 144px/ contain no-repeat url("@/assets/imgs/homes/industry_4.png");
		background-size: 215px 230px ;
	}

	.industryText {
	 
		.titleDes {
			padding: 0 15px;

		}
	}


}

.industryData {
	border-radius: 10px;
	margin: 0 0 30px 0;
	padding: 25px 18px;
	background: center / contain no-repeat;
	// background: linear-gradient(120deg, rgba(22, 117, 247, 0.05), rgba(255, 255, 255, 0));

}

.industryIcons {
	margin-right: 23px;
	background: center / contain no-repeat;
}
</style>
