import httpRequest from '@/utils/request'
// export async function queryProse(): Promise<any> {
//   return request('/project/prose')
// }
export const perFix=import.meta.env.VITE_APP_API;
// export const perFix='';
 
// 提交预约产品体验申请 
	export async   function experienceAdd(parm){
		return httpRequest({
			url: `${perFix}/admin/product/experience/add`,
			method: 'post',
			params: parm,
		})
	}
	// 提交咨询相关业务申请
	export async  function businessAdd(parm){
		return httpRequest({
			url: `${perFix}/admin/consult/business/add`,
			method: 'post',
			params: parm,
		})
	}
	// 提交获取产业报告申请
	export async  function recordAdd(parm){
		return httpRequest({
			url: `${perFix}/admin/industry/report/record/add`,
			method: 'post',
			params: parm,
		})
	}
	// 获取短信验证码
	export async  function sendCode(parm){
		return httpRequest({
			url: `${perFix}/admin/sms/log/sendVerificationCode`,
			method: 'post',
			params: parm,
		})
	}
		 
	// / 获取短信验证码
	export async  function getReportList(parm){
		return httpRequest({
			url: `${perFix}/admin/industry/report/page`,
			method: 'post',
			params: parm,
		})
	}